import { Pipe, PipeTransform } from '@angular/core';
import { AvailableFlightsResponse } from '@zupper/aerial-components';
import * as _ from 'lodash';

@Pipe({
  name: 'uniqueFlight'
})
export class UniqueFlightPipe implements PipeTransform {
  flightList: Array<string> = [];

  addFlight(flightId: string): boolean {
    let isFlightAdded = this.flightList.indexOf(flightId);
    this.flightList.push(flightId);
    return isFlightAdded == -1;
  }

  transform(flights: Array<AvailableFlightsResponse>, sliceId: number = 0): Array<AvailableFlightsResponse> {
    this.flightList = [];
    let filteredFlights: Array<AvailableFlightsResponse> = [];

    filteredFlights = _.uniqBy(flights, (flight) => {
      return flight.slices[sliceId].apiSliceId;
    });

    let sortedFlights = filteredFlights.sort((f1, f2) => {
      if (f1.slices[sliceId].departureDate > f2.slices[sliceId].departureDate) {
        return 1;
      }

      return -1;
    });

    return sortedFlights;
  }
}
