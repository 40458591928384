import { Component, Input, OnInit } from '@angular/core';
import { ApiFilterModel } from '../../../../models/api-filter.model';
import { AirportService, AvailableFlightsRequest, AvailableFlightsResponse, SliceResponse } from '@zupper/aerial-components';
import { ApiFlightType } from '@zupper/data';
import * as moment from 'moment';
import { startCase } from 'lodash';
import { AirlineServiceService } from '../airline-service.service';

@Component({
  selector: 'app-flight-group',
  templateUrl: './flight-group.component.html',
  styleUrls: ['./flight-group.component.scss']
})
export class FlightGroupComponent implements OnInit {
  @Input() priceGroup: AvailableFlightsResponse[] = [];
  @Input() flightGroupsInitialShow: number = 5;
  @Input() flightGroupsTotal: number = 5;
  @Input() totalSlices: number = 0;
  @Input() filterMeta: ApiFilterModel[] = [];
  @Input() flightsSearchFields: AvailableFlightsRequest = new AvailableFlightsRequest();
  @Input() flightList: AvailableFlightsResponse[][] = [];
  @Input() selectedFlightList: Array<Array<string>> = [];
  @Input() showFamilies: boolean = false;
  @Input() isMobile: boolean = false;

  @Input() groupIndex: number;

  chosenSliceList: SliceResponse[][] = [];
  selectedFamilyName: Array<string> = [];
  selectedFlightResponseByFamily: AvailableFlightsResponse = new AvailableFlightsResponse();

  get isInternational(): boolean {
    return !!this.priceGroup[0].international;
  }

  constructor(
    private _airportsService: AirportService,
    private airlineServiceService: AirlineServiceService
  ) { }

  ngOnInit(): void {
  }

  getDirectionClass(sliceIndex: number) {
    let direction = this.getSliceIdentification(sliceIndex);
    return direction == "Volta" ? "flight-destination" : "flight-origin";
  }

  getSliceIdentification(sliceIndex: number) {
    let flightSearch: AvailableFlightsRequest = JSON.parse(
      sessionStorage.getItem("flightSearch")
    );
    if (flightSearch?.type == ApiFlightType.RoundTrip) {
      return sliceIndex == 0 ? "Ida" : "Volta";
    } else {
      if (flightSearch.slices.length == 1) {
        return "Ida";
      } else {
        return `Trecho ${sliceIndex + 1}`;
      }
    }
  }

  getSliceIdentificationDate(sliceIndex: number) {
    let flightSearch: AvailableFlightsRequest = JSON.parse(
      sessionStorage.getItem("flightSearch")
    );
    if (flightSearch.type == ApiFlightType.RoundTrip) {
      return sliceIndex == 0 ?
        this._formatDateString(flightSearch.slices[sliceIndex]?.departureDate)
        : this._formatDateString(flightSearch.slices[sliceIndex]?.departureDate);
    } else {
      if (flightSearch.slices.length == 1) {
        return this._formatDateString(flightSearch.slices[sliceIndex]?.departureDate);
      }
    }
  }

  private _formatDateString(dateString: string): string {
    const formattedDate = moment(dateString).format('D [de] MMMM');
    const capitalizedMonth = startCase(formattedDate.split(' ')[2]);
    return formattedDate.replace(formattedDate.split(' ')[2], capitalizedMonth);
  }

  private _findAirport(iataCode: string, slice: SliceResponse): string {
    let result = '';
    let airport = null;
    if (!this.priceGroup[0].international) {
      airport = this._airportDefault(iataCode, slice);
    } else if (slice.source === 'Sabre') {
      airport = this.filterMeta['Sabre'].airports[iataCode];
    } else {
      airport = this._airportDefault(iataCode, slice);
    }

    result = iataCode.concat(" - ", airport.city);
    return result;
  }

  private _airportDefault(iataCode: string, slice: SliceResponse) {
    if (this.filterMeta[slice.issuingAirline]) {
      return this.filterMeta[this.airlineServiceService.getFilteredAirlines(slice.issuingAirline)].airports[iataCode];
    } else {
      return Object.values(this.filterMeta)[0].airports[iataCode];
    }
  }

  departureAirport(slice: SliceResponse) {
    let iataCode = slice.origin;
    try {
      return this._findAirport(iataCode, slice);
    } catch (e) {

      return iataCode;
    }
  }

  arrivalAirport(slice: SliceResponse) {
    let iataCode = slice.destination;
    try {
      return this._findAirport(iataCode, slice);
    } catch (e) {

      return iataCode;
    }
  }

  saveChosenSlice(slice: SliceResponse, groupIndex: number, sliceIndex: number) {
    if (!this.chosenSliceList[groupIndex]) {
      this.chosenSliceList[groupIndex] = [];
    }
    if (!this.chosenSliceList[groupIndex][sliceIndex]) {
      this.chosenSliceList[groupIndex][sliceIndex] = new SliceResponse();
    }
    this.chosenSliceList[groupIndex][sliceIndex] = slice;
  }

  setSelectedFamily(groupIndex: number, familyName: string) {
    this.selectedFamilyName[groupIndex] = familyName;
    this.flightsSearchFields.slices.forEach((slice, index) => {
      let selectedFlight = this.flightList[groupIndex].filter((flight) => {
        const slice = flight.slices[index];
        const selectedApiSliceId = this.selectedFlightList[groupIndex][index];
        return flight.ciasDiff ? slice.apiSliceId == selectedApiSliceId : slice.mainFamily == familyName && slice.apiSliceId == selectedApiSliceId;
      });

      this.selectedFlightResponseByFamily = selectedFlight[0];
      this.saveChosenSlice(selectedFlight[0]?.slices[index], groupIndex, index);
    });
  }

  get selectedFlightResponseByFamilyCheck() {
    return this.selectedFlightResponseByFamily?.airline ? this.selectedFlightResponseByFamily : this.priceGroup[0];
  }

  saveChosenFlight(flight: AvailableFlightsResponse, groupIndex: number, sliceIndex: number) {
    if (!this.selectedFlightList[groupIndex]) {
      this.selectedFlightList[groupIndex] = [];
    }
    if (!this.selectedFlightList[groupIndex][sliceIndex]) {
      this.selectedFlightList[groupIndex][sliceIndex] = null;
    }
    this.selectedFlightList[groupIndex][sliceIndex] =
      flight.slices[sliceIndex].apiSliceId;
  }

  get isMultiCias(): boolean {
    return !!this.priceGroup.find(_x => !!_x.ciasDiff);
  }

}
